/* Responsive CSS File */
@media (max-width: 768px) {
  .page-section .content-detail {
    padding: 0 15px;
  }
  .page-section .content-detail .pendulum {
    width: 160px;
    height: 160px;
    bottom: -150px;
    left: -70px;
    font-size: 70px;
  }
  .page-section .content-detail .pendulum-shadow {
    width: 100px;
    margin: 170px auto 0;
  }
  .page-section .content-detail .global-title {
    font-size: 120px;
    font-weight: 700;
  }
  .page-section .content-detail .sub-title {
    font-weight: 500;
    font-size: 25px;
  }
  .page-section .content-detail .detail-text {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .page-section .content-detail .pendulum-platform {
    width: 320px;
  }
  .page-section .content-detail .text-detail {
    padding-top: 280px;
  }
  .page-section .content-detail .pendulum-shadow {
    display: none;
  }
}
@media (max-width: 480px) {
  .page-section .content-detail {
    padding: 0 15px;
  }
  .page-section .content-detail .global-title {
    font-size: 80px;
    font-weight: 600;
  }
  .page-section .content-detail .sub-title {
    font-weight: 600;
    font-size: 30px;
    padding-top: 20px;
  }
  .detail-text {
    font-size: 14px;
    margin-bottom: 0;
    line-height: normal;
    text-align: center;
    padding: 10px 5px;
  }
  .detail-subtext {
    display: block;
    color: #111;
    font-size: 10px;
    line-height: normal;
    text-align: center;
    padding: 5px 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 360px) {
  .page-section .content-detail .pendulum-platform {
    width: 300px;
  }
}
/*# sourceMappingURL=error-page-responsive.css.map */
