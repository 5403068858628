@use '../utils' as *;

@import url($font-url);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
  font-family: var(--tp-ff-body);
  font-size: var(--tp-fz-body);
  font-weight: normal;
  color: var(--tp-text-body);
  line-height: 26px;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tp-ff-heading);
  color: var(--tp-heading-primary);
  margin-top: 0px;
  font-weight: var(--tp-fw-regular);
  line-height: 1.3;
  @include transition(0.3s);
}

h1 {
  font-size: var(--tp-fz-h1);
}

h2 {
  font-size: var(--tp-fz-h2);
}

h3 {
  font-size: var(--tp-fz-h3);
}

h4 {
  font-size: var(--tp-fz-h4);
}

h5 {
  font-size: var(--tp-fz-h5);
}

h6 {
  font-size: var(--tp-fz-h6);
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

p {
  font-family: var(--tp-ff-p);
  font-size: var(--tp-fz-p);
  font-weight: var(--tp-fw-normal);
  color: var(--tp-text-body);
  margin-bottom: 15px;
  line-height: 26px;
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  @include transition(0.3s);
}

hr {
  color: var(--tp-border-2);
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
}

input[type='color'] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
img {
  max-width: 100%;
  height: auto;
}

.w-img {
  & img {
    width: 100%;
  }
}

.m-img {
  & img {
    max-width: 100%;
  }
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  @include background();
}

/*----------------------------------------
    Body Overlay 
-----------------------------------------*/

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  opacity: 0;
  visibility: hidden;
  @include transition(0.3s);

  &:hover {
    cursor: pointer;
  }
}

.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

/*----------------------------------------
    Progress Wrap
-----------------------------------------*/

.progress-wrap {
  @media #{$xs} {
    right: 15px;
    bottom: 15px;
  }
}

// basic pagination

.basic-pagination {
  @media #{$xs,$md} {
    margin-bottom: 30px;
  }

  & ul {
    & li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 10px;
      }
      @media #{$xs} {
        margin-bottom: 30px;
      }

      & a,
      & span {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 46px;
        text-align: center;
        border: 2px solid #f1f1f1;
        font-size: 15px;
        font-weight: 500;

        @media #{$xs} {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
        &:hover,
        &.current {
          background: var(--tp-theme-1);
          border-color: var(--tp-theme-1);
          color: var(--tp-common-white);
        }
      }
    }
  }
}

// nice select arrow

.nice-select {
  &::after {
    border: none;
    background-color: transparent;
    @include transform(translateY(-50%));
    margin-top: 0;
    right: 0;
    content: '\f107';
    font-family: var(--tp-ff-fontawesome);
    transform-origin: center;
    color: var(--tp-common-white);
    font-weight: 500;
    height: auto;
    width: auto;
  }

  &.open {
    &::after {
      @include transform(translateY(-50%) rotate(-180deg));
    }
  }
}

// other
.tp-border-line-about {
  padding-top: 200px;

  @media #{$sm, $xs} {
    padding-top: 50px;
  }

  & hr {
    height: 1px;
    color: var(--tp-border-1);
  }
}
.tp-border-line {
  & hr {
    height: 1px;
    color: var(--tp-border-1);
  }
}
.tp-brand-slider {
  padding-bottom: 60px;
  overflow: hidden;
}
.tp-brand-border {
  border-top: 1px solid var(--tp-border-2);
  border-bottom: 1px solid var(--tp-border-2);
}

.tp-brand-area {
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.1);
  @media #{$xs} {
    margin-bottom: 0;
  }
}

.tp-hr-border {
  padding: 0.5px;
  background-color: var(--tp-border-2);
  transform: translateY(-125px);
  position: relative;
  z-index: -1;
}

.tp-cta-wrapper {
  margin-top: 100px;
  @media #{$xs} {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0px;
  }

  @media #{$md} {
    padding-top: 170px;
  }
}

.tp-cta-2-right {
  & p {
    padding-right: 100px;
  }
}

.tp-cta-2-left {
  & p {
    padding-right: 80px;
  }
}

.scale-1 {
  overflow: hidden;

  &:hover {
    & img {
      transform: scale(1.1);
    }
  }
}
