.loader-wrapper {
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  top: 0;
}

.loader {
  border: 16px solid #111;
  border-radius: 50%;
  border-top: 16px double #9874d8;
  border-bottom: 16px double #9874d8;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*==============
  [01] Page css
=================*/
.page-section {
  background-image: url('../../../../../../public/assets/img/bg/bg-404.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
}
.page-section .content-detail {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.page-section .content-detail .sub-title,
.page-section .content-detail .detail-text {
  display: block;
  color: #111;
  text-align: center;
}
.page-section .content-detail .sub-title {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 30px;
}
.detail-text {
  font-size: 18px;
  margin-bottom: 0;
}
.detail-subtext {
  display: block;
  color: #111;
  font-size: 13px;
}
.page-section .content-detail .global-title {
  display: block;
  color: #5bc86d;
  font-size: 150px;
  font-weight: 800;
}
.page-section .content-detail .global-title span {
  -webkit-animation: animateblur 1s linear forwards;
  animation: animateblur 1s linear forwards;
}
.page-section .content-detail .global-title span:nth-child(1) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.page-section .content-detail .global-title span:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.page-section .content-detail .global-title span:nth-child(3) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@-webkit-keyframes animateblur {
  0% {
    opacity: 0;
    -webkit-filter: blur(10px);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
}
@keyframes animateblur {
  0% {
    opacity: 0;
    -webkit-filter: blur(10px);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
}
.page-section .content-detail .back-btn {
  margin-top: 15px;
  text-align: center;
}
.page-section .content-detail .back-btn .btn {
  padding: 8px 15px;
  color: #f2edf9;
  border-radius: 30px;
  font-size: 14px;
  background: #4833a7;
  border: 1px solid #a7a2bb;
}
.page-section .content-detail .back-btn .btn:hover {
  background-color: #9874d8;
  color: #fff;
}
.page-section .content-detail .pendulum-platform {
  width: 500px;
  border-top: 5px solid #555;
  margin: auto;
  text-align: center;
  position: absolute;
  padding: 0 0 30px;
  top: 0;
}
.page-section .content-detail .pendulum-holder {
  width: 50px;
  height: 40px;
  background: #555;
  margin: auto;
  border-radius: 0 0 50% 50%;
}
.page-section .content-detail .pendulum-thread {
  display: inline-block;
  width: 20px;
  height: 130px;
  background: #111;
  border-radius: 20px;
  position: relative;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-animation: pendulum-move 2.5s ease-in-out infinite;
  animation: pendulum-move 2.5s ease-in-out infinite;
}
.page-section .content-detail .pendulum-thread:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #111;
  border: 2px solid white;
  top: -4.5px;
  left: 4.5px;
}
.page-section .content-detail .pendulum-knob {
  width: 17px;
  height: 16px;
  position: absolute;
  top: -29px;
  left: 1.4px;
  border: 5px solid #111;
  border-radius: 50%;
  border-bottom: 2px solid transparent;
}
.page-section .content-detail .pendulum {
  width: 200px;
  height: 200px;
  background-color: var(--tp-common-white);
  border-radius: 3%;
  position: absolute;
  bottom: -190px;
  left: -90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 90px;
  font-weight: 800;
  color: #111;
  -webkit-border-radius: 3%;
  -moz-border-radius: 3%;
  -ms-border-radius: 3%;
  -o-border-radius: 3%;
}
.page-section .content-detail .pendulum-shadow {
  width: 110px;
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin: 215px auto 0;
  -webkit-animation: pendulum-shadow 1.1 ease-in-out alternate infinite;
  animation: pendulum-shadow 1.1 ease-in-out alternate infinite;
}
@-webkit-keyframes pendulum-move {
  0%,
  100% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  50% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
}
@keyframes pendulum-move {
  0%,
  100% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  50% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
}
@-webkit-keyframes pendulum-shadow {
  0% {
    -webkit-transform: translateX(-220px) scale(1.4, 0.5);
    transform: translateX(-220px) scale(1.4, 0.5);
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
  50% {
    -webkit-filter: blur(3px);
    filter: blur(3px);
  }
  100% {
    -webkit-transform: translateX(220px) scale(1.4, 0.5);
    transform: translateX(220px) scale(1.4, 0.5);
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
}
@keyframes pendulum-shadow {
  0% {
    -webkit-transform: translateX(-220px) scale(1.4, 0.5);
    transform: translateX(-220px) scale(1.4, 0.5);
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
  50% {
    -webkit-filter: blur(3px);
    filter: blur(3px);
  }
  100% {
    -webkit-transform: translateX(220px) scale(1.4, 0.5);
    transform: translateX(220px) scale(1.4, 0.5);
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
}
.page-section .content-detail .text-detail {
  padding-top: 400px;
  text-transform: uppercase;
}

/*# sourceMappingURL=error-page.css.map */
