@use '../utils' as *;

/*----------------------------------------*/
/*  08. TEAM CSS START
/*----------------------------------------*/

.tp-team-area {
  background: var(--tp-grey-3);
  img {
    width: 100%;
  }

  @media #{$xs} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp-team {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffff;
  &__thumb {
    overflow: hidden;

    &:hover {
      & img {
        transform: scale(1.1);
      }
    }
  }

  &__info {
    & .tp-team-name {
      font-size: 20px;

      & a {
        background-image: linear-gradient(#000, #000),
          linear-gradient(#000, #000);
        display: inline;
        background-size:
          0% 1px,
          0 1px;
        background-position:
          100% 100%,
          0 100%;
        background-repeat: no-repeat;
        transition: background-size 0.4s linear;

        &:hover {
          color: #000;
          background-size:
            0 1px,
            100% 1px;
        }
      }
    }

    & p {
      color: var(--tp-grey-1);
      text-transform: uppercase;
      font-size: 12px;
      font-family: var(--tp-ff-heading);
      letter-spacing: 0.18em;
    }
  }

  &__social {
    & a {
      &:not(:last-child) {
        margin-right: 15px;
      }

      display: inline-block;
      color: var(--tp-grey-1);

      &:hover {
        color: var(--tp-common-black);
      }
    }
  }

  @media #{$md,$sm,$xs} {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 40px;
  }
}

.team-single-page {
  @media #{$xs} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.team-hero-img {
  width: auto;
  height: 10%;
}

.team-main-content {
  @media #{$xs} {
    padding-bottom: 50px;
  }
}

.team-info-box {
  position: absolute;
  bottom: 0;
  right: 0;

  @media #{$xs} {
    position: static;
    bottom: inherit;
    padding: 50px 0;
  }

  & span {
    font-size: 18px;
    color: var(--tp-common-black);
    text-decoration: underline;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }
}

.t-box-title {
  font-size: 43px;

  @media #{$xs} {
    font-size: 30px;
  }
}

.team-ct-form {
  @media #{$xs} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.team-member-info-list {
  ul {
    li {
      font-size: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.team-social-icons {
  & span {
    color: var(--tp-grey-1);

    & a {
      width: 42px;
      height: 42px;
      display: inline-block;
      border: 1px solid #f1f1f1;
      text-align: center;
      line-height: 42px;
      margin-right: 15px;

      &:hover {
        background-color: var(--tp-common-black);
        color: var(--tp-common-white);
      }
    }
  }
}

.team-title-text {
  font-size: 30px;
}

.team-title-name {
  font-weight: 500;
}

.team-ct-heading {
  & span {
    color: var(--tp-grey-1);
    text-transform: uppercase;
    font-size: 12px;
    font-family: var(--tp-ff-heading);
    letter-spacing: 0.18em;
  }
}

.team-ct-heading-text {
  font-size: 43px;

  @media #{$xs} {
    font-size: 35px;
  }
}

.team-page-content {
  @media #{$xs} {
    padding-right: 15px;
  }
}

.tp-promotion-progress {
  @media #{$xs} {
    padding-right: 15px;
  }
}

.team_banner {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
