@use '../utils' as *;

/*----------------------------------------*/
/*  05. SERVICE CSS START
/*----------------------------------------*/

.tp-service {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media #{$xs} {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__icon {
    font-size: 52px;
    color: var(--tp-common-black);
  }

  &__title {
    font-size: 20px;

    & a {
      background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
      display: inline;
      background-size:
        0% 1px,
        0 1px;
      background-position:
        100% 100%,
        0 100%;
      background-repeat: no-repeat;
      transition: background-size 0.4s linear;

      &:hover {
        color: #000;
        background-size:
          0 1px,
          100% 1px;
      }
    }
  }

  p {
    font-size: 15px;
    margin-bottom: 25px;
    padding: 0 10px;
  }
}

//  service page style here

.tp-service-ara {
  @media #{$xs} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.tp-ab-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.tp-ab-text {
  padding-right: 200px;

  @media #{$lg,$md} {
    padding-left: 60px;
    padding-right: 30px;
  }

  @media #{$xs} {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.service-description {
  min-height: 120px;
  display: block;
  overflow: hidden;
}

.serive-vd-play {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 128px;
  height: 128px;
  left: 0;
  right: 0;
  margin: 0 auto;

  & button {
    display: block;
    text-align: center;
    line-height: 128px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--tp-common-white);
    font-size: 25px;
    color: var(--tp-common-black);
    border: 1px solid var(--tp-common-black);
  }
}

.tp-ct-info {
  @media #{$xs} {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.tp-ct-info-border {
  border-bottom: 1px solid #2f2f2f;
}

.tp-ct-info-icons {
  & a {
    width: 60px;
    height: 60px;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.06);
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    margin-right: 15px;
    font-size: 20px;
    color: var(--tp-common-white);
    &:hover {
      color: var(--tp-common-black);
      background-color: var(--tp-common-white);
    }
    @media #{$lg} {
      margin-right: 5px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
    @media #{$xs} {
      margin-right: 5px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }
}

.tp-contact-form {
  @media #{$md,$xs} {
    margin-right: 0;
  }

  @media #{$xs} {
    padding-left: 0;
    padding-right: 0;
  }
}

.tp-sv-inner-img {
  img {
    border-radius: 10px;
  }
}

.tp_sv__video-bg {
  border-radius: 10px;
  background-size: cover;
}

.tp-sv {
  &__content {
    & p {
      padding-bottom: 20px;
    }
  }

  &__title {
    font-size: 43px;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 34px;
    margin-top: 80px;
    margin-bottom: 20px;
    @media #{$xs} {
      font-size: 24px;
    }
  }
}

.sv-video {
  margin: 100px auto;
  width: 128px;
  height: 128px;
  background-color: var(--tp-common-white);
  font-size: 20px;
  text-align: center;
  display: block;
  border-radius: 50%;
  line-height: 128px;
  border: 1px solid var(--tp-common-black);
  color: #000;
}
