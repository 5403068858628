$font-family: 'Poppins', sans-serif;
$color-white: #fff;
$color-detail-text: #f2edf9;

/*==============
  [00] Page General css
=================*/
body {
  font-family: $font-family;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family;
}
.loader-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  top: 0;
}
.loader {
  border: 16px solid #111;
  border-radius: 50%;
  border-top: 16px double #9874d8;
  border-bottom: 16px double #9874d8;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*==============
  [01] Page css
=================*/
.page-section {
  background-image: url('../images/background-1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  .content-detail {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .sub-title,
    .detail-text {
      display: block;
      color: $color-detail-text;
      text-align: center;
    }
    .sub-title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 30px;
    }
    .global-title {
      display: block;
      color: #5bc86d;
      font-size: 150px;
      font-weight: 800;
      span {
        animation: animateblur 1s linear forwards;
        &:nth-child(1) {
          animation-delay: 1s;
        }
        &:nth-child(2) {
          animation-delay: 2s;
        }
        &:nth-child(3) {
          animation-delay: 3s;
        }
      }
      @keyframes animateblur {
        0% {
          opacity: 0;
          filter: blur(10px);
        }
        100% {
          opacity: 1;
          filter: blur(0px);
        }
      }
    }
    .back-btn {
      margin-top: 15px;
      text-align: center;
      .btn {
        padding: 8px 15px;
        color: #f2edf9;
        border-radius: 30px;
        font-size: 14px;
        background: #4833a7;
        border: 1px solid #a7a2bb;
        &:hover {
          background-color: #9874d8;
          color: $color-white;
        }
      }
    }
    .pendulum-platform {
      width: 500px;
      border-top: 5px solid #555;
      margin: auto;
      text-align: center;
      position: absolute;
      padding: 0 0 30px;
      top: 0;
    }
    .pendulum-holder {
      width: 50px;
      height: 40px;
      background: #555;
      margin: auto;
      border-radius: 0 0 50% 50%;
    }
    .pendulum-thread {
      display: inline-block;
      width: 20px;
      height: 130px;
      background: #8a57c5;
      border-radius: 20px;
      position: relative;
      transform-origin: 50% 0;
      animation: pendulum-move 2.5s ease-in-out infinite;
    }
    .pendulum-thread:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #8a57c5;
      border: 2px solid white;
      top: -4.5px;
      left: 4.5px;
    }
    .pendulum-knob {
      width: 17px;
      height: 16px;
      position: absolute;
      top: -29px;
      left: 1.4px;
      border: 5px solid #8a57c5;
      border-radius: 50%;
      border-bottom: 2px solid transparent;
    }
    .pendulum {
      width: 200px;
      height: 200px;
      background: rgba(152, 116, 216, 1);
      border-radius: 50%;
      position: absolute;
      bottom: -190px;
      left: -90px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
      font-weight: 800;
      color: #f2edf9;
    }
    .pendulum-shadow {
      width: 130px;
      height: 40px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      margin: 210px auto 0;
      animation: pendulum-shadow 4.5s ease-in-out alternate infinite;
      -webkit-animation: pendulum-shadow 4.5s ease-in-out alternate infinite;
    }
    @keyframes pendulum-move {
      0%,
      100% {
        transform: rotate(30deg);
        -webkit-transform: rotate(30deg);
        -moz-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        -o-transform: rotate(30deg);
      }
      50% {
        transform: rotate(-30deg);
        -webkit-transform: rotate(-30deg);
        -moz-transform: rotate(-30deg);
        -ms-transform: rotate(-30deg);
        -o-transform: rotate(-30deg);
      }
    }
    @keyframes pendulum-shadow {
      0% {
        transform: translateX(-220px) scale(1.4, 0.5);
        filter: blur(20px);
      }
      50% {
        filter: blur(3px);
      }
      100% {
        transform: translateX(220px) scale(1.4, 0.5);
        filter: blur(20px);
      }
    }
    .text-detail {
      padding-top: 400px;
    }
  }
}
