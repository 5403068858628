@use '../utils' as *;

.tp-accordion {
  & .accordion-body {
    padding: 20px 15px;
    padding-top: 0;
    font-family: var(--tp-ff-heading);
    font-size: 16px;

    @media #{$sm,$xs} {
      font-size: 12px;
    }
  }
  & .accordion-item {
    margin-bottom: 10px;
    border: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .accordion-button {
    color: var(--tp-common-black);
    font-family: var(--tp-ff-heading);
    font-weight: 400;
    padding: 15px 15px;
    margin-bottom: 35px;
    border-bottom: 1px solid #f1f1f1;
    transition: background-color 0.5s ease;
    font-size: calc(24px - 0.3vw);
    height: 90px;
    max-height: 90px;

    @media #{$sm,$xs} {
      font-size: 14px;
      height: auto;
      margin-bottom: 15px;
    }

    &:not(.collapsed) {
      border-bottom: 1px solid #f1f1f1;
      background: none;
      box-shadow: none;
      margin-bottom: 35px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      &::after {
        width: auto;
        height: auto;
        content: '\f068';
        transform: rotate(0deg);
      }
    }

    &:focus {
      box-shadow: none;
      border-color: transparent;
      border-bottom: 1px solid #f1f1f1;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    }

    &::after {
      width: auto;
      height: auto;
      content: '\f067';
      font-family: var(--tp-ff-fontawesome);
      background-image: none;
      font-size: 24px;
      color: var(--tp-common-black);
    }
  }
  .accordion-button:hover {
    background-color: #d8d8d8;
  }
}

.tp-accordion-title {
  font-size: 43px;
  @media #{$xs} {
    font-size: 25px;
  }
}

.test {
  & .accordion-body {
    padding: 25px 0;
    padding-top: 0;
  }

  & .accordion-item {
    border: none;
    margin-bottom: 25px;
  }
  & .accordion-button {
    padding: 15px 0;
    font-size: 24px;

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }

    &.collapsed {
      border-bottom: 1px solid red;
      margin-bottom: 35px;
    }
    &::after {
      background-image: none;
      content: '\f067';
      font-family: var(--tp-ff-fontawesome);
      text-decoration: none;
    }
  }

  & .accordion-button:not(.collapsed) {
    background-color: var(--tp-common-white);
    color: var(--tp-common-black);
    box-shadow: none;
    text-decoration: underline;
    border-bottom: 1px solid red;
    margin-bottom: 35px;
    &::after {
      background-image: none;
      content: '\f068';
      font-family: var(--tp-ff-fontawesome);
      text-decoration: none;
    }
  }
}
