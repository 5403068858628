
/* Responsive CSS File */
@media (max-width: 768px) {
  .page-section {
    .content-detail{
      padding: 0 15px;
      .pendulum {
        width: 160px;
        height: 160px;
        bottom: -150px;
        left: -70px;
      }
      .pendulum-shadow {
        width: 100px;
        margin: 170px auto 0;
      }
      .global-title {
        font-size: 120px;
        font-weight: 700;
      }
      .sub-title {
        font-weight: 500;
        font-size: 25px;
      }
      .detail-text{
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 576px) {
  .page-section {
    .content-detail{
    .pendulum-platform {
      width: 320px;
    }
    .text-detail {
      padding-top: 280px;
    }
    .pendulum-shadow {
        display: none;
      }
    }
  }   
}
@media (max-width: 480px) {
  .page-section {
    .content-detail{
      padding: 0 15px;
      .global-title {
        font-size: 80px;
        font-weight: 600;
      }
      .sub-title {
        font-weight: 500;
        font-size: 22px;
      }
    }
  }
}
@media (max-width: 360px) {
  .page-section {
    .content-detail{
      .pendulum-platform {
      width: 300px;
    }
    }
  }
}